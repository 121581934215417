import { FC, useEffect, useState, memo} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { close_btn, maximize, reset } from "assets/images";
import { Document, Page, pdfjs } from "react-pdf";
import "./PDFViewer.scss";
import React from "react";
import Loader from "common/components/base/Loader/Loader";
import ReactDOM from "react-dom";
import { Viewer,Worker } from "@react-pdf-viewer/core";
import { IDocumentDetails } from "common/models/chat/reference-document.model";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { chatActions } from "modules/gpo/redux/chat/chat.slice";

type PDFViewerProps = {};
const PDFViewer: FC<PDFViewerProps> = ({
}: PDFViewerProps) => 
{
    const [fileStatus, setFileStatus] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [shown, setShown] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const [pageNum, setPageNum] = useState(-1);
    const [documentName, setDocumentName] = useState("");
    const [documentGuid, setDocumentGuid] = useState("");

    const chatState = useAppSelector((state: RootState) => state.chat);
    const chatStateDispatch = useAppDispatch();

   useEffect(() => {
     if (chatState?.isDocumentOpen) {
      setFileLoading(true);
      const wrapperDiv = document.getElementById("gpo-custom-chat-wrapper");
      if (wrapperDiv) {
        wrapperDiv.classList.add("gpo-multiple-wrapper");
        const heightDiv = document.getElementById('history-'+chatState?.chatScrollHeight)?document.getElementById('history-'+chatState?.chatScrollHeight):document.getElementById('live-chat_'+chatState?.chatScrollHeight);
        const messagesWrapper = document.getElementsByClassName('gpo-custom-chat-messages')[0];
        if(messagesWrapper && heightDiv){
          heightDiv.scrollIntoView({block: "end", inline:"nearest"});
        }
      }

     }
    }, [chatState?.isDocumentOpen]);

    useEffect(() => {
      if(chatState?.viewDocURL && chatState?.viewDocPageNum && chatState?.viewDocPageNum?.length){
        setPdfUrl(chatState?.viewDocURL);
        setPageNum(chatState?.viewDocPageNum[0]?.pageNumber);
      }       
    },[chatState?.viewDocURL,chatState?.viewDocPageNum]);

    useEffect(() => {
      if(chatState?.viewDocGuid){
        setDocumentGuid(chatState?.viewDocGuid);
        getDocumentPDFURL();
      }       
     },[chatState?.viewDocGuid]);

    useEffect(()=>{
      if(chatState?.pdfDocumentDetails){
        const documentDetails : IDocumentDetails = chatState?.pdfDocumentDetails; 
        chatStateDispatch(chatActions.updateChatDocUrlState(documentDetails?.sasUrl));  
        setDocumentName(documentDetails?.documentName);
      } 
    },[chatState?.pdfDocumentDetails])

    const getDocumentPDFURL =  () =>{
      chatStateDispatch(chatActions.getPDFDetails(chatState?.viewDocGuid));    
    }

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setFileLoading(false);
        setFileStatus(true);
      };
    
      const handleCloseDoc = () => {
        setFileStatus(false);
        chatStateDispatch(chatActions?.updateDocOpenState(false));
        const wrapperDiv = document.getElementById("gpo-custom-chat-wrapper");
        if(wrapperDiv){
          wrapperDiv.classList.remove("gpo-multiple-wrapper");
        } 
        const messagesWrapper = document.getElementsByClassName('gpo-custom-chat-messages')[0];
        const heightDiv = document.getElementById('history-'+chatState?.chatScrollHeight)?document.getElementById('history-'+chatState?.chatScrollHeight):document.getElementById('live-chat_'+chatState?.chatScrollHeight);
        if(messagesWrapper && heightDiv){
          heightDiv.scrollIntoView({block: "end", inline:"nearest"});
        }
        // if(messagesWrapper){
        //   messagesWrapper.scroll({ top: messagesWrapper.scrollHeight});
        // }
      };


  const modalBody = () => (
    <div className="fullscreen-modal">
      <header>
        <span>{documentName}</span>
        <img src={close_btn} onClick={() => setShown(false)} />
      </header>
      <div className="fullscreen-modal__body">
       <Worker workerUrl={"/pdf.worker_3_5_127.js"}>
        <Viewer  fileUrl={pdfUrl}
          initialPage={pageNum-1} />
      </Worker>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {chatState.isDocumentOpen && (
        <div className="gpo-pdf-viewer">
          <div className="doc">
            <div className="gpo-pdf-header">
              {fileStatus &&(
              <span onClick={() => setShown(true)}>
                <img src={maximize} />
              </span>
              )}
              <span className="close_btn" onClick={handleCloseDoc}>
                <img src={close_btn} />
                Close
              </span>
            </div>
           {fileLoading && <Loader />}
           <Document
            file={pdfUrl}
            options={{ workerSrc: "/pdf.worker.js" }}
            onLoadSuccess={onDocumentLoadSuccess}
            >
            <Page pageNumber={pageNum} />
          </Document>
          </div>
        </div>
      )}
      {shown && ReactDOM.createPortal(modalBody(), document.body)}
    </React.Fragment>
  );
};

export default memo(PDFViewer);
