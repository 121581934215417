import React, { useEffect, useRef, useState } from "react";
import { createDirectLine, createStore } from "botframework-webchat";
import { Components } from "botframework-webchat-component";
import Chat from "../Chat/Chat";
import Loader from "common/components/base/Loader/Loader";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./Chatbot.scss";
import PDFViewer from "../PDFViewer/PDFViewer";
import { IDocumentPage } from "common/models/chat/reference-document.model";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";
import { chatActions } from "modules/gpo/redux/chat";
import Messages from "../Messages/Messages";

const ChatBot = () => {
  const [loader, setLoader] = useState(true);
  const [token, setToken] = useState("");
  
  const userState = useAppSelector((state: RootState) => state.user);
  const chatHistoryState = useAppSelector((state: RootState) => state.chatHistory);
  const userStateDispatch = useAppDispatch(); 
  const chatStateDispatch = useAppDispatch(); 

  useEffect(() => {
    getToken();
  }, []);

  useEffect(()=>{
    if(userState?.botToken){
      setToken(userState?.botToken);  
    }
  },[userState?.botToken])

  const store = createStore(
    {},
    ({ dispatch }: any) =>
      (next: any) =>
      (action: any) => {
        if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
          setLoader(false);
        }
        return next(action);
      }
  );

  const getToken = async () => {
    // let xhr = new XMLHttpRequest();
    // xhr.open("GET", "https://webchat.botframework.com/api/tokens", true);
    // xhr.setRequestHeader(
    //   "Authorization",
    //   "BotConnector " +
    //     "cFc9X8-55lo.2kk0s3NJB1X1uYeJVbANThPuNdCXzAi30UYleZj1Opc"
    // );
    // xhr.send();
    // xhr.onreadystatechange = processRequest;
    // function processRequest() {
    //   if (xhr.readyState == 4 && xhr.status == 200) {
    //     var response = JSON.parse(xhr.responseText);
    //     setToken(response);
    //   }
    // }
    userStateDispatch(userActions?.getBotConversationToken());   
  };

  const handleViewDoc = (pageNum: IDocumentPage[], documentGuid:string,activityId:string) => {
    chatStateDispatch(chatActions?.updateChatScrollHeight(activityId));
    chatStateDispatch(chatActions?.updateChatDocGuidState(documentGuid));
    chatStateDispatch(chatActions?.updateChatDocPageNumState(pageNum));
    chatStateDispatch(chatActions?.updateDocOpenState(true));
  };

  return (
    <React.Fragment>
      {/* {loader && <Loader fullScreen />}
      {!!token && ( */}
        <Components.Composer
          directLine={createDirectLine({ token })}
          userID="r_1675964726"
          store={store}
        >
          <div className="gpo-custom-chat-container">
          {/* <div className={chatHistoryState?.searchTriggered && chatHistoryState?.searchValue!="" ? "gpo-custom-chat-container message-list":"gpo-custom-chat-container"}> */}
            {/* {chatHistoryState?.searchTriggered && chatHistoryState?.searchValue!="" &&( */}
            <Messages/>
            {/* )} */}
            <div className="gpo-custom-chat-container__chat-wrapper">
            <div id="gpo-custom-chat-wrapper" >
                <Chat onViewDoc={handleViewDoc} />            
                <PDFViewer/>                
              </div>
            </div>
          </div>
        </Components.Composer>
      {/* )} */}
    </React.Fragment>
  );
};

export default ChatBot;
