import React from "react";
import { useNavigate } from "react-router-dom";

import { bot, bot_logo, hello_icon } from "assets/images";

import "./Welcome.scss";

const Welcome = () => {
  const navigate = useNavigate();
  return (
    <div className="welcome-wrapper">
      <div className="welcome-wrapper__container">
        <header>
          EY Intelligent Payroll Chatbot <span>(GPO Chatbot)</span>
        </header>
        <section>
          <div className="notes">
            <h1>EY Intelligent Payroll Chatbot will help with your payroll queries</h1>
            <p>
            It’s a EY.ai Large Language Model (LLM)-powered intelligent chatbot to answer payroll questions.
            </p>
            <button type="button" onClick={() => navigate("chat")}>
              START CHAT
            </button>
          </div>
          {/* <img src={bot} /> */}
          <div className="bot-logo">
            <img src={bot_logo} />
            <div className="bot-label">
              <img src={hello_icon} />
              <span className="bot-label__text">Hello, I'm GPO Chatbot!</span>
            </div>
          </div>  
        </section>
      </div>
    </div>
  );
};

export default Welcome;
