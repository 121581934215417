import { UserChatHistory } from "common/models/chat/chat-history.model";
import { IDocumentDetails, IDocumentPage } from "common/models/chat/reference-document.model";

interface IChatState{
    userFeedbackState:any;
    pdfDocumentDetails:IDocumentDetails|undefined;
    handsOffAcknowledged:Array<{id:string,message:string}>;
    viewDocURL:string;
    viewDocGuid:string;
    viewDocPageNum : IDocumentPage[];
    isDocumentOpen : boolean;
    chatScrollHeight:any;
    agentLeavingAcknowledged:Array<{id:string,message:string}>;
    messageInputEnabled : boolean;
    messagesDisplayed : Array<string>;
    chatSearchResults : UserChatHistory[];
    searchMessages : UserChatHistory[]|undefined; 
    chatScrolled:boolean;
}

const initalChatState: IChatState = {
    userFeedbackState:undefined,
    pdfDocumentDetails:undefined,
    handsOffAcknowledged:[],
    viewDocURL:"",
    viewDocGuid:"",
    viewDocPageNum:[],
    isDocumentOpen:false,
    chatScrollHeight:undefined,
    agentLeavingAcknowledged:[],
    messageInputEnabled:false,
    messagesDisplayed:[],
    chatSearchResults:[],
    searchMessages:undefined,
    chatScrolled:false
};

export { initalChatState };
