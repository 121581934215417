import { FC, useEffect, useState } from "react";
import { profile_logout } from "assets/images";
import { useMsal, useAccount } from '@azure/msal-react';
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";
import Search from "../Search/Search";

import { defaultUserIcon } from "assets/media";

import "./Header.scss";

type HeaderProps = {
  showProfileImage: boolean;
  showSearch:boolean;
};

const Header: FC<HeaderProps> = ({
  showProfileImage,
  showSearch
}: HeaderProps) => {

  
  const [profileImage, setProfileImage] = useState(defaultUserIcon);
  const [userDetails, setUserDetails] = useState<any>({});
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const userState = useAppSelector((state: RootState) => state.user);
  const userStateDispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    userStateDispatch(userActions.getCurrentUserAvatar())
  }, []);

  useEffect(() => {
    if (userState?.avatar)
      setProfileImage(userState?.avatar);
  }, [userState?.avatar]);

  useEffect(() => {
    document.body.addEventListener('click', hideProfileMenu);
  }, []);

  useEffect(() => {
    if (userState?.userDetails) {
      setUserDetails(userState?.userDetails);
    }
  }, [userState?.userDetails])

  useEffect(() => {
    if (userState?.logoutSuccess?.isSuccess) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/logout")
    }
  }, [userState?.logoutSuccess])

  const hideProfileMenu = () => {
    setShowProfile(false);
  }
  const logoutFromApplication = async () => {
    userStateDispatch(userActions?.signOutUser());
  }
  return (
      <div className="gpo-chat-header">
        <div className="gpo-chat-header__container">
          <div className="gpo-logo">
            EY Intelligent Payroll Chatbot <span>(GPO Chatbot)</span>
          </div>
          {showSearch && (
          <Search/>
          )}
          {showProfileImage && (
          <div className="gpo-profile">
            <img src={profileImage} onClick={(event: any) => { event.stopPropagation(); setShowProfile(!showProfile); }} />
            {showProfile && (
              <div className="gpo-profile__details" onClick={(event: any) => { event.stopPropagation(); }}>
                <div className="profile-name">
                  <img src={profileImage} />
                  <span className="displayName">{userDetails?.displayName}</span>
                  <span>{userDetails?.emailId}</span>
                </div>
                <div className="logout" onClick={() => logoutFromApplication()}>
                  <span>
                    <img src={profile_logout} />
                    Logout
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default Header;
