import { right_arrow } from "assets/images";
import React, { FC } from "react";
import "./Popup.scss";
import { IDocumentPage, IReferenceDocument } from "common/models/chat/reference-document.model";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";

type PopupProps = {
  similarityScore: number;
  documents:IReferenceDocument[],
  activityId:string,
  onViewDoc: (pageNum: IDocumentPage[], documentGuid:string,activityId:string) => void;
};
const Popup: FC<PopupProps> = ({
  similarityScore,
  documents,
  activityId,
  onViewDoc,
}: PopupProps) => {
  const chatState = useAppSelector((state: RootState) => state.chat);
   
  const getStatus = () => {
    if (similarityScore < 50) {
      return (
        <span className={`status status--poor`}>
          {Math.round(similarityScore) + "%"}
        </span>
      );
    } else if (similarityScore >= 50 && similarityScore <= 70) {
      return (
        <span className={`status status--fair`}>
          {Math.round(similarityScore) + "%"}
        </span>
      );
    } else if (similarityScore > 70) {
      return (
        <span className={`status status--excellent`}>
          {Math.round(similarityScore) + "%"}
        </span>
      );
    }
  };
  return (
     <React.Fragment>
      {!chatState.isDocumentOpen && (
     <div className="gpo-tooltip">
      <div className="gpo-tooltip__container">
        <div className="row">
          <span className="title">Confident score</span>
          {getStatus()}
        </div>
        <div className="row">
          <span className="title">Source</span>
          <span className="pages">Page {documents[0]?.pages.map((obj) => obj.pageNumber)?.join(',')}</span>
        </div>
        <div className="row">
          <span></span>
          <span className="view" onClick={() => onViewDoc(documents[0]?.pages,documents[0]?.documentGuid,activityId)}>
            <img src={right_arrow} /> View in document
          </span>
        </div>
      </div>
    </div>
     )}
     </React.Fragment>
     
    
  );
};

export default Popup;
